import React from 'react';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import './App.css';



import ChartOptions from './components/display/ChartOptions';
import MetricChart from './components/display/MetricChart';
import MetricDatapoints from './components/display/MetricDatapoints';
import MetricInfo from './components/display/MetricInfo';

import HashInvalid from './components/HashInvalid';
import NoMonitoring from './components/NoMonitoring';
import { getThemeProps } from '@material-ui/styles';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:hash/:widget?" children={<MetricDisplay />} />
      </Switch>
    </Router>
  )
}

function MetricDisplay() {
  const [data, setData] = useState(null);
  let { hash, widget } = useParams();
  useEffect(() => {
    async function getData() {
      const res = await fetch(`/api/data?hash=${hash}`);
      const newData = await res.text();
      console.log(newData)
      let json = JSON.parse(newData);
      if (json.title.length > 140) {
        json.title = json.title.substr(0,140) + "...";
      }
      json.chartData = []
      json.historicalData.map((datapoint) => {
        json.chartData.push({
            "timestamp": new Date (parseInt(datapoint.timestamp)).toLocaleString(), 
            "value": parseFloat(datapoint.value.replace(/[^\d.-]/g, '')), 
          });
      });
      console.log(json.chartData)
      setData(json);
    }
    getData();
  }, []);
  let metricData;
  if (!widget) {
    metricData = (
      <main>
        <div className="topbar">
          <a href="https://dashtastic.com" className="logo">
            <img src="logo.png" />
          </a>
          <span className="metric-title">{data ? data.title : ""}</span>
        </div>
        
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data ? <MetricChart chartData={data.chartData} /> : <br />}
          </Grid>
          <Grid item xs={7}>
            {data ? <MetricDatapoints metric={data}/> : <br />}
          </Grid>
          <Grid item xs={5}>
            {data ? <MetricInfo metric={data}/> : <br />}
            <ChartOptions />
          </Grid>
        </Grid>
      </main>
    );
  } else {
    metricData = (
      <main>
          {data ? <MetricChart chartData={data.chartData} /> : <br />}
      </main>
    );
  }

  let loading = (
    <h1>Loading...</h1>
  )

  return data ? metricData : loading;
}

export default App;
