import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles({
    root: {
      width: '90vw',
      height: '55vh',
      margin: "auto",
    },
    chart: {
      width: '96%',
      height: '92%',
      margin: "auto",
      paddingTop: "15px"
    },
});

export default function MetricChart(props) {
  const classes = useStyles();
 
  const lineChart = (
    // A react-chart hyper-responsively and continuously fills the available
    // space of its parent element automatically
    <Paper className={classes.root}>
      <ResponsiveContainer width="97%" height="94%">
        <LineChart
          data={props.chartData}
          margin={{
            top: 45, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#8884d8"/>
        </LineChart>
      </ResponsiveContainer>
    </Paper>
    
  )

  return lineChart;
}