import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
  tableContainer: {
      marginLeft: "5vw",
      maxWidth: "50vw"
  }
});

function createData(timestamp, value) {
  return { timestamp, value };
}

const rows = [
  createData('5AM', 21),
  createData('6AM', 24),
  createData('7AM', 25),
  createData('8AM', 22),
  createData('9AM', 26),
  createData('10AM', 27),
];

export default function MetricDatapoints(props) {
  const classes = useStyles();

  let datapoints = props.metric.historicalData ? props.metric.historicalData : []

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datapoints.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {new Date (row.timestamp).toLocaleString()}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}