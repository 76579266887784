import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { Mail, Link, Twitter } from '@material-ui/icons';

const useStyles = makeStyles({
    root: {
        marginRight: "5vw",
        marginTop: "15px"
    },
    label: {
        textAlign: "left",
        marginBottom: "0px",
        marginTop: "4px",
        float: "right",
        paddingTop: "0px",
        fontWeight: "bold"
    },
    shareItem: {
        display: "inline",
        color: "blue",
    },
    shareIcon: {
        fontSize: "32px"
    }
});

export default function ChartOptions() {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <p className={classes.label}>Share: </p>
                </Grid>
                <Grid item xs={1}>
                    <a href="" className={classes.shareItem}>
                        <Mail className={classes.shareIcon} />
                    </a>
                </Grid>
                <Grid item xs={1}>
                    <a href="" className={classes.shareItem}>
                        <Twitter className={classes.shareIcon} />
                    </a>
                </Grid>
                <Grid item xs={1}>
                    <a href="" className={classes.shareItem}>
                        <Link className={classes.shareIcon} />
                    </a>
                </Grid>
                <Grid item xs={7}>
                    
                    
                </Grid>
            </Grid>
        </div>
    );
}
