import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    root: {
        marginRight: "5vw",
        padding: "10px"
    },
    current: {
        textAlign: "center",
        marginTop: "5px"
    },
    currentValue: {
        fontSize: "60px"
    },
    currentTime: {
        fontSize: "12px"
    },
    creation: {
        textAlign: "left"
    },
    creatorName: {
        margin: "0px"
    },
    monitor: {
        marginTop: "10px",
        padding: "4px"
    },
    monitorLabel: {
        margin: "0px",
        fontSize: "12px"
    },
    monitorThreshold: {
        margin: "0px",
        fontSize: "28px"
    }
});

export default function MetricInfo(props) {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={5}>
                    <div className={classes.current}>
                        <span className={classes.currentValue}> {props.metric.lastValue} </span>
                        <br style={{height: "100px"}}/>
                        <span className={classes.currentTime}>{ "as of " + new Date(props.metric.lastChecked).toLocaleString() }</span>
                    </div>
                </Grid>
                <Grid item xs={7}>
                    <div className={classes.creation}>
                        <h3 className={classes.creatorName}>{"Created by " + props.metric.userEmail}</h3>
                        <span className={classes.created}>{ "On " + new Date(props.metric.createdAt).toDateString() }</span>
                    </div>
                    <Paper className={classes.monitor}>
                        <h6 className={classes.monitorLabel}>Monitor Threshold</h6>
                        <h4 className={classes.monitorThreshold}>{props.metric.monitorThreshold !=  "" ? props.metric.monitorThreshold : "None"}</h4>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}
